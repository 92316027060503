<template>
  <div>
    <!-- Start Containerbar -->
    <div id="containerbar">
      <!-- Start Rightbar -->
      <div class="rightbar">
        <div class="breadcrumbbar">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
              <h4 class="page-title">Solicitudes</h4>
              <div class="breadcrumb-list">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/dashboard">{{ $t("shipment_request.home") }}</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a @click="changeAction(1)">Solicitudes</a>
                  </li>
                  <li class="breadcrumb-item">{{ $t(title) }}</li>
                </ol>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
              <button
                v-if="
                  showAction(1) &&
                    withPermissionName(
                      permissions.PRE_REQUEST_APPLICATION_ADD_01
                    )
                "
                class="btn btn-outline-success"
                @click="changeAction(2)"
              >
                <i class="ti-plus mr-2"></i>{{ $t("global.add_request") }}
              </button>
              <button
                class="btn btn-outline-success"
                v-if="showAction(2) || showAction(3)"
                @click="changeAction(1)"
              >
                <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
              </button>
            </div>
          </div>
        </div>

        <div class="contentbar">
          <div class="row">
            <div class="col-lg-12 col-xl-12">
              <div class="card">
                <AlertMessageComponent ref="alertMessageComponent" />
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="card-title">
                        <i
                          class="feather icon-clipboard"
                          aria-hidden="true"
                        ></i>
                        {{ $t(title) }}
                      </h5>
                    </div>
                  </div>
                </div>
                <TableRequestComponent
                  v-show="showAction(1)"
                  :headers="headers"
                  :dataList="dataList"
                  :isUpdate="true"
                  :isDetail="
                    withPermissionName(
                      permissions.PRE_REQUEST_APPLICATION_DETA_02
                    )
                  "
                  :detailRequest="detailRequest"
                  :updateRequest="updateRequest"
                  :userRequest="true"
                  :isPDF="
                    withPermissionName(
                      permissions.PRE_REQUEST_APPLICATION_DL_PDF_04
                    )
                  "
                />
                <div v-if="showAction(2)">
                  <div class="col-md-12 p-0">
                    <div class="col-md-12 pb-0">
                      <h6>
                        <v-icon
                          class="pb-1"
                          v-text="'mdi-numeric-1-box-outline'"
                        />
                        {{ $t("shipment_request.title") }}
                      </h6>
                    </div>
                    <RequestFormComponent
                      v-bind:modelRequest="modelRequest"
                      ref="requestFormComponent"
                    />
                    <RequestAddProductsComponent
                      :productList="productList"
                      :productListDistributionTable="
                        productListDistributionTable
                      "
                      :modelRequest="modelRequest"
                    />
                    <ButtonAction
                      :onClick="onClick"
                      :buttonTitle="buttonTitle"
                      :validator="false"
                      :sending="false"
                    />
                  </div>
                </div>
                <div v-if="showAction(3)">
                  <div class="col-md-12">
                    <h6>
                      <v-icon
                        class="pb-1"
                        v-text="'mdi-numeric-1-box-outline'"
                      />
                      {{ $t("shipment_request.title") }}
                    </h6>
                    <RequestFormComponent
                      v-bind:modelRequest="modelRequest"
                      ref="requestFormComponent"
                    />

                    <ButtonAction
                      :onClick="onClick"
                      :buttonTitle="buttonTitle"
                      :validator="false"
                      :sending="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalRequestComponent
      :dialog="dialog"
      :modelDetailRequest="modelDetailRequest"
      :addProductCnis="false"
      :addProductDistribution="false"
      :saveRequest="false"
      :userRequest="true"
      :setRequestValidation="setRequestValidation"
      :persistent="persistent"
    />
  </div>
</template>
<script>
/**
 * @module RequestView
 * @desc Solicitudes
 */
import { UtilFront, PermissionConstants } from "@/core";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { mapState, mapMutations } from "vuex";
import { PRERESQUEST } from "@/core/DataTableHeadersLocale";
import { SwalConfirm } from "@/core/SwalAlert";
import TableRequestComponent from "@/components/shipments-request/TableRequestComponent";
import RequestFormComponent from "@/components/shipments-request/request/RequestFormComponent";
import RequestAddProductsComponent from "@/components/shipments-request/request/RequestAddProductsComponent";
import ModalRequestComponent from "@/components/shipments-request/ModalRequestComponent";
import ButtonAction from "@/common/button/ButtonAction";
import { PreRequest } from "@/core/request";

export default {
  name: "RequestView",
  data() {
    return {
      action: 1,
      originIdBranchoffice: "",
      headers: PRERESQUEST(),
      modelRequest: {
        idShipmentRequest: "",
        refereceRequest: "",
        typeRequest: "",
        destination: "",
        comments: "",
      },
      dialog: {
        dialog: false,
      },
      modelDetailRequest: {},
      sending: false,
      /* Permisos */
      permissions: PermissionConstants,
      loading: false,
      dataList: [],
      productListDistributionTable: [],
      productListCnisSelect: [],
      /* List products for add */
      productList: {
        productListDistribution: [],
        productListCnis: [],
      },
      persistent: {
        persistent: false,
      },
    };
  },
  computed: {
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    title() {
      return this.action == 1
        ? "shipment_request.shipment_request"
        : this.action == 2
        ? "shipment_request.add_shipment_request"
        : "shipment_request.update_shipment_request";
    },
    buttonTitle() {
      return this.action == 2
        ? "Guardar Solicitud"
        : this.action == 3
        ? "Modificar Solicitud"
        : this.action == 4
        ? "Cambio de remisión"
        : this.action == 5
        ? "Modificar información"
        : "";
    },
  },
  methods: {
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action, type) {
      action == 1 && type ? await this.getInfoGeneral() : null;
      action == 2 ? this.clear() : null;
      this.action = action;
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    clear() {
      this.productList.productListCnis = [];
      this.modelRequest.idShipmentRequest = this.modelRequest.comments = this.modelRequest.refereceRequest = this.modelRequest.destination = this.modelRequest.typeRequest =
        "";
    },
    /**
     * Ejecuta el servicio para guardar la solicitud creada
     * @method
     * @param {Array} productDistribution - Se envian solo los productos del cuadro de distribución seleccionado
     * @param {Array} productCnis - Se envia la lista de los productos seleccionado
     * comentario complementario spagobi, es el nombre real, por ejemplo "facultad", "tipo", "etc."
     */
    async addRequest(productDistribution, productCnis) {
      if (productCnis.length == 0 && productDistribution.length == 0) {
        this.alert("error", "Error_Product_Add_0001");
        return;
      } else if (this.modelRequest.destination === this.originIdBranchoffice) {
        this.alert("error", "Message_Branch_Destination_0002");
        return;
      } else {
        let ordersProduct = productDistribution.concat(productCnis);
        const { isConfirmed } = await SwalConfirm.fire({
          text:
            "La información de la solicitud será registrada y ya no se podra modificar.",
        });
        if (!isConfirmed) {
          return;
        }
        this.loading = UtilFront.getLoadding();
        let saveRequest = {
          userBranchOffice: {
            idUser: UtilFront.getDataUser().idUser,
            idBranchOffice: this.originIdBranchoffice,
          },
          requestKey: this.modelRequest.refereceRequest,
          comments: this.modelRequest.comments,
          typeRequest: this.modelRequest.typeRequest,
          destination: {
            idBranchOffice: this.modelRequest.destination,
          },
          ordersProduct: ordersProduct,
        };
        PreRequest.addRequestSave(saveRequest)
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.loading.hide();
              this.changeAction(1, true);
              this.alert("success", "Succces_Remmision_Add_0001");
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error(error);
          })
          .finally(() => {
            this.loading.hide();
          });
      }
    },
    /**
     * Este metodo depende del tipo de action que se encuentre realiza una funcion en especifico Nota: Este metodo se manda a llamar desde el common {@link component:ButtonAction}
     * @method
     */
    async onClick() {
      switch (this.action) {
        case 2:
          // Espera la validación del formulario para realizar el registro al metodo addRequest()
          var isValid = await this.$refs.requestFormComponent.validateForm();
          if (isValid) {
            // Obtiene los productos tanto del cuadro de distribución con los ajenos al mismo,
            // en un formato especifico que envia a al servicio
            const productDistribution = await this.getProductBoard();
            const productCnis = await this.getProductCnis();
            this.addRequest(productDistribution, productCnis);
          }
          break;
        case 3:
          // Espera la validación del formulario para realizar el registro al metodo addRequest()
          var isValid = await this.$refs.requestFormComponent.validateForm();
          if (isValid) {
            //Guardo la remision - pendiente de realizar
            console.log("Enviar solicitud para modificar");
          }
          break;
        default:
          break;
      }
    },
    /**
     * Este realiza la consulta de las solicitudes que se encuentra registradas
     * @method
     */
    async getPrerequest() {
      await PreRequest.getAllRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        typeUser: 1, // Ti
      })
        .then((response) => {
          let { data, message, success } = response.data.data;
          if (success) {
            this.dataList = data;
          } else {
            this.dataList = [];
          }
        })
        .catch(() => {
          console.log(error);
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    /**
     * Este realiza la consulta de las solicitudes que se encuentra registradas
     * @method
     */
    async getProductBoardDistribution() {
      await PreRequest.getProductBoardDistribution({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originIdBranchoffice,
      })
        .then((response) => {
          let { data } = response.data.data;
          this.productListDistributionTable = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {});
    },
    /**
     * Este realiza la consulta de las solicitudes que se encuentra registradas
     * @param {Object} item - Se envia la información de la solicitud seleccionada
     * @method
     */
    detailRequest(item) {
      this.loading = UtilFront.getLoadding();
      PreRequest.detailRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        idShipmentRequest: item.idShipmentRequest,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.modelDetailRequest = data;
            this.dialog.dialog = true;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Este realiza la consulta de las solicitudes que se encuentra registradas
     * @param {String} idShipmentRequest - Se envia el identificador de la solicitud
     * @param {Number} status - El estatus de la solicitud a la que sera cambiado
     * @method
     */
    async setRequestValidation(idShipmentRequest, status) {
      this.persistent.persistent = true;
      const { isConfirmed } = await SwalConfirm.fire({
        html:
          status == 2
            ? "La solicitud con el identificador " +
              "<div><b>" +
              idShipmentRequest +
              "</b>" +
              " será cancelada."
            : "La solicitud con el identificador " +
              "<div><b>" +
              idShipmentRequest +
              "</b>" +
              " será enviada para verificar.",
        icon: status == 2 ? "warning" : "question",
      });
      if (!isConfirmed) {
        return;
      }
      this.persistent.persistent = false;
      this.loading = UtilFront.getLoadding();
      PreRequest.setStatusRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        idShipmentRequest: idShipmentRequest,
        statusRequest: status,
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.loading.hide();
            this.getInfoGeneral();
            this.dialog.dialog = false;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Este realiza la consulta de las solicitudes que se encuentra registradas
     * @param {Object} item - Se envia el identificador de la solicitud
     * @method
     */
    updateRequest(item) {
      this.loading = UtilFront.getLoadding();
      PreRequest.detailRequest({
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.originIdBranchoffice,
        },
        idShipmentRequest: item.idShipmentRequest,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          if (success) {
            this.modelRequest.idShipmentRequest = data.idShipmentRequest;
            this.modelRequest.refereceRequest = data.requestKey;
            this.modelRequest.typeRequest = data.typeRequest;
            this.modelRequest.supplierRequest = data.destination.idBranchOffice;
            this.modelRequest.comments =
              data.shipmentRequestComments[0].comment;
            this.changeAction(3);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Este realiza la reestructuración de la lista de los productos del cuadro de distribución, en un formato especifico
     * que solicito el servicio, para el registro del mismo.
     * @method
     * @returns {Array} productDistribution - Lista de los productos del cuadro de distribución
     */
    async getProductBoard() {
      let productDistribution = [];
      this.productListDistributionTable.map((product) => {
        if (product.quantity == undefined || product.quantity == "") {
        } else {
          let products = {
            distributionBoardProducts: {
              idDistributionProduct: product.value,
            },
            quantityOrdersProduct: parseInt(product.quantity),
          };
          productDistribution.push(products);
        }
      });
      return productDistribution;
    },
    /**
     * Este realiza la reestructuración de la lista de los productos ajenos al cuadro de distribución, en un formato especifico
     * que solicito el servicio, para el registro del mismo.
     * @method
     * @returns {Array} productCnis - Lista de los productos ajenos al cuadro de distribución
     */
    async getProductCnis() {
      let productCnis = [];
      this.productList.productListCnis.map((product) => {
        let products = {
          products: {
            idProduct: product.products.idProduct,
          },
          quantityOrdersProduct: parseInt(product.quantityOrdersProduct),
        };
        productCnis.push(products);
      });
      return productCnis;
    },
    /**
     * Consulta las solicitudes y la lista de los los productos del cuadro de distribución
     * @method
     */
    async getInfoGeneral() {
      this.loading = UtilFront.getLoadding();
      try {
        await this.getProductBoardDistribution(); // Consulta los productos del cuadro de distribución
        await this.getPrerequest(); // Consulta las solicitudes
        this.loading.hide();
      } catch (error) {
        this.error(error);
      } finally {
        this.loading.hide();
      }
    },
  },
  /**
   * Consulta las solicitudes y la lista de los los productos del cuadro de distribución
   * @created
   */
  async created() {
    this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
    this.updateAction(1);
    this.action = 1;
    await this.getInfoGeneral();
  },
  watch: {
    async branchOfficeState() {
      this.originIdBranchoffice = this.branchOfficeState.idBranchoffice;
      await this.getInfoGeneral();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = PRERESQUEST();
    },
  },
  /**
   * @description Componenetes utilizado en la vista principal de solicitudes
   * @prop {Components} RequestFormComponent {@link component:RequestFormComponent} - Este componente es el formulario para la creacion de una solicitud
   * @prop {Components} TableRequestComponent {@link component:TableRequestComponent} - Este es el componente de TableRequestComponent que es la tabla principal de los sub-modulo de solicitudes.
   * @prop {Common} ButtonAction {@link component:ButtonAction} - Este es el componente de TableRequestComponent que es la tabla principal de los sub-modulo de solicitudes.
   * @prop {Components} ModalRequestComponent {@link component:ModalRequestComponent} - Este es el componente ModalRequestComponent que se utiliza para el detalle de la solicitud.
   */
  components: {
    AlertMessageComponent,
    RequestFormComponent,
    TableRequestComponent,
    ButtonAction,
    ModalRequestComponent,
    RequestAddProductsComponent,
  },
};
</script>
