var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"containerbar"}},[_c('div',{staticClass:"rightbar"},[_c('div',{staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12"},[_c('h4',{staticClass:"page-title"},[_vm._v("Solicitudes")]),_c('div',{staticClass:"breadcrumb-list"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v(_vm._s(_vm.$t("shipment_request.home")))])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{on:{"click":function($event){return _vm.changeAction(1)}}},[_vm._v("Solicitudes")])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])])])]),_c('div',{staticClass:"col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button"},[(
                _vm.showAction(1) &&
                  _vm.withPermissionName(
                    _vm.permissions.PRE_REQUEST_APPLICATION_ADD_01
                  )
              )?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(2)}}},[_c('i',{staticClass:"ti-plus mr-2"}),_vm._v(_vm._s(_vm.$t("global.add_request"))+" ")]):_vm._e(),(_vm.showAction(2) || _vm.showAction(3))?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(1)}}},[_c('i',{staticClass:"ti-arrow-left mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.return_to"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('AlertMessageComponent',{ref:"alertMessageComponent"}),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"card-title"},[_c('i',{staticClass:"feather icon-clipboard",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])])]),_c('TableRequestComponent',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAction(1)),expression:"showAction(1)"}],attrs:{"headers":_vm.headers,"dataList":_vm.dataList,"isUpdate":true,"isDetail":_vm.withPermissionName(
                    _vm.permissions.PRE_REQUEST_APPLICATION_DETA_02
                  ),"detailRequest":_vm.detailRequest,"updateRequest":_vm.updateRequest,"userRequest":true,"isPDF":_vm.withPermissionName(
                    _vm.permissions.PRE_REQUEST_APPLICATION_DL_PDF_04
                  )}}),(_vm.showAction(2))?_c('div',[_c('div',{staticClass:"col-md-12 p-0"},[_c('div',{staticClass:"col-md-12 pb-0"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("shipment_request.title"))+" ")],1)]),_c('RequestFormComponent',{ref:"requestFormComponent",attrs:{"modelRequest":_vm.modelRequest}}),_c('RequestAddProductsComponent',{attrs:{"productList":_vm.productList,"productListDistributionTable":_vm.productListDistributionTable,"modelRequest":_vm.modelRequest}}),_c('ButtonAction',{attrs:{"onClick":_vm.onClick,"buttonTitle":_vm.buttonTitle,"validator":false,"sending":false}})],1)]):_vm._e(),(_vm.showAction(3))?_c('div',[_c('div',{staticClass:"col-md-12"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("shipment_request.title"))+" ")],1),_c('RequestFormComponent',{ref:"requestFormComponent",attrs:{"modelRequest":_vm.modelRequest}}),_c('ButtonAction',{attrs:{"onClick":_vm.onClick,"buttonTitle":_vm.buttonTitle,"validator":false,"sending":false}})],1)]):_vm._e()],1)])])])])]),_c('ModalRequestComponent',{attrs:{"dialog":_vm.dialog,"modelDetailRequest":_vm.modelDetailRequest,"addProductCnis":false,"addProductDistribution":false,"saveRequest":false,"userRequest":true,"setRequestValidation":_vm.setRequestValidation,"persistent":_vm.persistent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }